<template>
  <div>
    <div class="select-card">
      <div class="select-card-top">
        <div>订单分析</div>
      </div>
      <div class="select-card-bottom">
        <div class="select-card-bottom-right">
          <el-input
            v-model="orderCode"
            placeholder="请输入服务单号"
            class="input_con"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            @click="get_detail"
            :loading="isLoading"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="select-card">
      <CardGrid :datas="cardList" v-if="flag1" />
    </div>
  </div>
</template>

<script>
import CardGrid from "./cardgrid_3.vue";
export default {
  components: {
    CardGrid,
  },
  data() {
    return {
      isLoading: false,
      flag1: false,
      orderCode: "",
      cardList: [
        {
          id: 1,
          title: "实际销售额（元）",
          num: 0,
          lineColor: require("@/assets/sale_slices/bg_xsfy_gray_m@2x.png"),
          remarks:
            "统计周期内，对app内线上支付的订单金额和月结订单金额求和，剔除赠品订单、快捷支付单和发生退款的订单，分为无税和有税两种口径。", // 备注
        },
        {
          id: 2,
          title: "实际成本额（元）",
          num: 0,
          lineColor: require("@/assets/sale_slices/bg_xsfy_gray_m@2x.png"),
          remarks:'统计周期内，lims实际核算的的订单成本额'
        },
        {
          id: 3,
          title: "标准成本额（元）",
          num: 0,
          lineColor: require("@/assets/sale_slices/bg_xsfy_gray_m@2x.png"),
          remarks:'统计周期内，对订单中的检测项目标准成本求和'
        },
        {
          id: 4,
          title: "实际毛利（元）",
          num: 0,
          lineColor: require("@/assets/sale_slices/bg_xsfy_gray_m@2x.png"),
          remarks:'实际销售额-实际成本额，销售额为无税口径'
        },
        {
          id: 5,
          title: "实际成本率",
          num: 0,
          lineColor: require("@/assets/sale_slices/bg_xsfy_gray_m@2x.png"),
          remarks:'实际成本额/实际销售额'
        },
        {
          id: 6,
          title: "标准成本率",
          num: 0,
          lineColor: require("@/assets/sale_slices/bg_xsfy_gray_m@2x.png"),
          remarks:'统计周期内，对订单中的检测项目标准成本求和'
        },
      ],
    };
  },
  created() {
    // 默认调用一下
    // this.get_detail();
  },
  methods: {
    get_detail() {
      this.axios
        .get("FINANCECOST_ORDERCOSTDETAILS",`?orderCode=${this.orderCode}`)
        .then((result) => {
          console.log("获取交易概览选择列表", result);
          this.flag1 = true;
          if (result.retCode == "200") {
            let data = result.data;
            this.cardList[0].num = data.orderIncome; //实际销售
            this.cardList[1].num = data.realityCost; //实际成本
            this.cardList[2].num = data.standardCost; //标准成本
            this.cardList[3].num = data.grossProfit; //实际毛利
            this.cardList[4].num = data.realityCostRate; //实际成本率
            this.cardList[5].num = data.standardCostRate; //标准成本率
          } else {
            this.$message.error(result.msg);
          }
        });
    },
  },
};
</script>

<style>
@media (max-width: 1440px) {
  /* 小于1440 */
  .week-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .week-input2 {
    margin-right: 0 !important;
    margin-top: 10px;
  }
  .text-btn2 {
    margin-left: 8px !important;
  }
  .text-btn3 {
    margin-left: 8px !important;
    margin-right: 0px !important;
  }
  .select-day {
    margin-left: 12px !important;
  }
  .class-list {
    margin-left: 8px !important;
  }
}
</style>

<style scoped lang="scss">

.input_con {
  width: 90%;
  margin-right: 12px;
  border-radius: 50%;
}
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  box-shadow: 0px 4px 32px 0px rgba(64, 98, 117, 0.12);
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 8px 24px 8px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex:1;
      flex-direction: row;
      align-items: center;
    }
  }
}
:deep(.el-input__inner){
  border-radius: 20px;
}
</style>